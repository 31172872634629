




























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
import { subExpertBasic } from "@/components/sign/index";
@Component({
    components: {
        "el-form": Form,
        "al-form-item": FormItemBox,
    },
})
export default class ExpertBasicForm extends Vue {
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    isshow: boolean = false;
    registItem_class_basic = "expert-regist";

    get registForm() {
        let opt = [
            {
                ref: "name",
                formkey: "name",
                type: "input",
                classname: `${this.registItem_class_basic}-name`,
                label: this.$t("sign.expert_name_label"),
                placeholder: this.$t("sign.expert_name_placeholder"),
            },
            {
                ref: "birthday",
                formkey: "birthday",
                type: "datePicker",
                classname: `${this.registItem_class_basic}-birthday`,
                label: this.$t("sign.expert_birthday_label"),
                placeholder: this.$t("sign.expert_birthday_placeholder"),
            },
            {
                ref: "email",
                formkey: "email",
                type: "input",
                classname: `${this.registItem_class_basic}-email`,
                label: this.$t("sign.expert_email_label"),
                placeholder: this.$t("sign.expert_email_placeholder"),
            },
            {
                ref: "phone",
                formkey: "phone",
                type: "input",
                classname: `${this.registItem_class_basic}-phone`,
                label: this.$t("sign.expert_phone_label"),
                placeholder: this.$t("sign.expert_phone_placeholder"),
            },
            {
                ref: "avatar",
                formkey: "avatar",
                type: "uploadimg",
                classname: `${this.registItem_class_basic}-avatar`,
                label: this.$t("sign.expert_avatar_label"),
                placeholder: this.$t("sign.expert_avatar_placeholder"),
            },
            {
                ref: "live_address",
                formkey: "live_address",
                type: "input",
                classname: `${this.registItem_class_basic}-live_address`,
                label: this.$t("sign.expert_liveAddress_label"),
                placeholder: this.$t("sign.expert_liveAddress_placeholder"),
            },

            {
                ref: "wechat",
                formkey: "wechat",
                type: "input",
                classname: `${this.registItem_class_basic}-wechat`,
                label: this.$t("sign.expert_wechat_label"),
                placeholder: this.$t("sign.expert_wechat_placeholder"),
            },

            {
                ref: "company",
                formkey: "company",
                type: "input",
                classname: `${this.registItem_class_basic}-company`,
                label: this.$t("sign.expert_company_label"),
                placeholder: this.$t("sign.expert_company_placeholder"),
            },
            {
                ref: "position",
                formkey: "position",
                type: "input",
                classname: `${this.registItem_class_basic}-position`,
                label: this.$t("sign.expert_position_label"),
                placeholder: this.$t("sign.expert_position_placeholder"),
            },
            {
                ref: "country_id",
                formkey: "country_id",
                type: "selector",
                classname: `${this.registItem_class_basic}-country_id`,
                label: this.$t("sign.expert_country_label"),
                placeholder: this.$t("sign.expert_country_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "country",
            },
            {
                ref: "graduate",
                formkey: "graduate",
                type: "input",
                classname: `${this.registItem_class_basic}-graduate`,
                label: this.$t("sign.expert_graduate_label"),
                placeholder: this.$t("sign.expert_graduate_placeholder"),
            },
            {
                ref: "degree",
                formkey: "degree",
                type: "input",
                classname: `${this.registItem_class_basic}-degree`,
                label: this.$t("sign.expert_degree_label"),
                placeholder: this.$t("sign.expert_degree_placeholder"),
            },
            {
                ref: "major",
                formkey: "major",
                type: "input",
                classname: `${this.registItem_class_basic}-major`,
                label: this.$t("sign.expert_major_label"),
                placeholder: this.$t("sign.expert_major_placeholder"),
            },
            {
                ref: "technical_field",
                formkey: "technical_field",
                type: "checkbox",
                classname: `${this.registItem_class_basic}-technical_field`,
                label: this.$t("sign.expert_technical_field_label"),
                placeholder: this.$t("sign.expert_technical_field_placeholder"),
                example: this.$t("sign.expert_technical_field_example"),
                apikey: "getProjectOpt",
                apiResKey: "field",
            },

            {
                ref: "custom_field",
                formkey: "custom_field",
                type: "input",
                classname: `${this.registItem_class_basic}-custom_field`,
                label: this.$t("sign.expert_customField_label"),
                placeholder: this.$t("sign.expert_customField_placeholder"),
            },

            {
                ref: "social_title",
                formkey: "social_title",
                type: "content",
                classname: [
                    `${this.registItem_class_basic}-social_title`,
                    "noFormItem",
                    "label-bold",
                    "label-nopadding",
                ],
                label: this.$t("sign.expert_social_title"),
                example: this.$t("sign.expert_social_title_example"),
            },
            {
                ref: "executive_position",
                formkey: "executive_position",
                type: "input",
                classname: `${this.registItem_class_basic}-executive_position`,
                label: this.$t("sign.expert_executive_position_label"),
                placeholder: this.$t(
                    "sign.expert_executive_position_placeholder"
                ),
            },
            {
                ref: "technical_position",
                formkey: "technical_position",
                type: "input",
                classname: `${this.registItem_class_basic}-technical_position`,
                label: this.$t("sign.expert_technicalPosition_label"),
                placeholder: this.$t(
                    "sign.expert_technicalPosition_placeholder"
                ),
            },
            {
                ref: "other_position",
                formkey: "other_position",
                type: "input",
                classname: `${this.registItem_class_basic}-other_position`,
                label: this.$t("sign.expert_otherPosition_label"),
                placeholder: this.$t("sign.expert_otherPosition_placeholder"),
            },

            {
                ref: "professional_title",
                formkey: "professional_title",
                type: "input",
                classname: `${this.registItem_class_basic}-professional_title`,
                label: this.$t("sign.expert_professional_title_label"),
                placeholder: this.$t(
                    "sign.expert_professional_title_placeholder"
                ),
                example: this.$t("sign.expert_professional_title_example"),
            },
            {
                ref: "faculty_position",
                formkey: "faculty_position",
                type: "input",
                classname: `${this.registItem_class_basic}-faculty_position`,
                label: this.$t("sign.expert_faculty_position_label"),
                placeholder: this.$t(
                    "sign.expert_faculty_position_placeholder"
                ),
                example: this.$t("sign.expert_faculty_position_example"),
            },
            {
                ref: "professional_affiliation",
                formkey: "professional_affiliation",
                type: "input",
                classname: `${this.registItem_class_basic}-professional_affiliation`,
                label: this.$t("sign.expert_professional_affiliation_label"),
                placeholder: this.$t(
                    "sign.expert_professional_affiliation_placeholder"
                ),
                example: this.$t(
                    "sign.expert_professional_affiliation_example"
                ),
            },
            {
                ref: "honor",
                formkey: "honor",
                type: "input",
                classname: `${this.registItem_class_basic}-honor`,
                label: this.$t("sign.expert_honor_label"),
                placeholder: this.$t("sign.expert_honor_placeholder"),
                example: this.$t("sign.expert_honor_example"),
            },

            {
                ref: "type",
                formkey: "type",
                type: "checkbox",
                classname: `${this.registItem_class_basic}-type`,
                label: this.$t("sign.expert_type_label"),
                placeholder: this.$t("sign.expert_type_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "expert_type",
            },
            {
                ref: "brief",
                formkey: "brief",
                type: "editor",
                classname: `${this.registItem_class_basic}-brief`,
                label: this.$t("sign.expert_brief_label"),
                placeholder: this.$t("sign.expert_brief_placeholder"),
            },
            // {
            //     formkey: "test",
            //     type: "radio",
            //     label: "表头",
            //     placeholder: "输入框中的提示，非必须",
            //     example: "示例，非必须",
            //     value: "默认值，非必须",
            //     children: [
            //         {
            //             text: "A",
            //             opt: {
            //                 /**提交的时候给的是前面的10，9，8，7，6，5，4，3，2，1,总之这每个选项的key得是唯一的
            //                  * 上传的时候大概这样，"题目的key":"选项的key"
            //                  */
            //                 "10": { en: "10 points", zh_CN: "10 分" },
            //                 "9": { en: "9 points", zh_CN: "9 分" },
            //                 "8": { en: "8 points", zh_CN: "8 分" }
            //             }
            //         },
            //         { text: "B", opt: [] },
            //         { text: "C", opt: [] },
            //         { text: "D", opt: [] }
            //     ],
            // }
        ];
        return opt;
    }

    /* 表单数据 */
    registFormData = {
        name: "",
        birthday: "",
        email: "",
        phone: "",
        avatar: "",
        live_address: "",
        technical_position: "",
        other_position: "",
        major: "",
        wechat: "",
        graduate: "",
        degree: "",
        company: "",
        position: "",
        country_id: "1",
        technical_field: "",
        brief: "",
        executive_position: "",
        professional_title: "",
        faculty_position: "",
        professional_affiliation: "",
        honor: "",
        type: "",
    };

    /**社会头衔验证关联key */
    validSocialTitleArr = [
        "social_title",
        "executive_position",
        "professional_title",
        "faculty_position",
        "professional_affiliation",
        "honor",
    ];
    /**社会头衔验证是否通过 */
    isValidSocialTitle: boolean = true;

    /* 社会头衔验证 */
    private validSocialTitle(rule: any, value: any, callback: any) {
        let data = this.registFormData;
        if (
            data.executive_position ||
            data.professional_title ||
            data.faculty_position ||
            data.professional_affiliation ||
            data.honor
        ) {
            callback();
            this.isValidSocialTitle != true
                ? (this.isValidSocialTitle = true)
                : undefined;
        } else {
            callback(
                new Error(
                    this.$t("sign.expert_social_title_rule_notnull") as string
                )
            );
            this.isValidSocialTitle != false
                ? (this.isValidSocialTitle = false)
                : undefined;
        }
    }

    /* 社会头衔验证 */
    private validSocialTitle_2(rule: any, value: any, callback: any) {
        let data = this.registFormData;
        if (
            data.executive_position ||
            data.professional_title ||
            data.faculty_position ||
            data.professional_affiliation ||
            data.honor
        ) {
            callback();
            this.isValidSocialTitle != true
                ? (this.isValidSocialTitle = true)
                : undefined;
        } else {
            callback(
                new Error(
                    this.$t("sign.expert_social_title_rule_notnull") as string
                )
            );
            this.isValidSocialTitle != false
                ? (this.isValidSocialTitle = false)
                : undefined;
        }
    }

    /* 注册表单验证规则 */
    get registFormRules() {
        let opt = {
            name: [
                {
                    required: true,
                    message: this.$t("sign.expert_name_rule_notnull"),
                    trigger: "blur",
                },
            ],
            birthday: [
                {
                    required: true,
                    message: this.$t("sign.expert_birthday_rule_notnull"),
                    trigger: "blur",
                },
            ],
            email: [
                {
                    required: true,
                    message: this.$t("sign.expert_email_rule_notnull"),
                    trigger: "blur",
                },
            ],
            phone: [
                {
                    required: true,
                    message: this.$t("sign.expert_phone_rule_notnull"),
                    trigger: "blur",
                },
            ],
            avatar: [
                {
                    required: true,
                    message: this.$t("sign.expert_avatar_rule_notnull"),
                    trigger: "blur",
                },
            ],
            graduate: [
                // {
                //     required: true,
                //     message: this.$t("sign.expert_graduate_rule_notnull"),
                //     trigger: "blur"
                // }
            ],
            degree: [
                // {
                //     required: true,
                //     message: this.$t("sign.expert_degree_rule_notnull"),
                //     trigger: "blur"
                // }
            ],
            company: [
                {
                    required: true,
                    message: this.$t("sign.expert_company_rule_notnull"),
                    trigger: "blur",
                },
            ],
            position: [
                {
                    required: true,
                    message: this.$t("sign.expert_position_rule_notnull"),
                    trigger: "blur",
                },
            ],
            technical_field: [
                {
                    required: true,
                    message: this.$t(
                        "sign.expert_technical_field_rule_notnull"
                    ),
                    trigger: "blur",
                },
            ],
            brief: [
                {
                    required: true,
                    message: this.$t("sign.expert_brief_rule_notnull"),
                    trigger: "change",
                },
            ],
            country_id: [
                {
                    required: true,
                    message: this.$t("sign.expert_country_rule_notnull"),
                    trigger: "blur",
                },
            ],
            type: [
                {
                    required: true,
                    message: this.$t("sign.expert_type_rule_notnull"),
                    trigger: "blur",
                },
            ],
            social_title: [
                // { validator: this.validSocialTitle, trigger: "blur" }
            ],
            executive_position: [
                // { validator: this.validSocialTitle_2, trigger: "blur" }
            ],
            professional_title: [
                // { validator: this.validSocialTitle_2, trigger: "blur" }
            ],
            faculty_position: [
                // { validator: this.validSocialTitle_2, trigger: "blur" }
            ],
            professional_affiliation: [
                // { validator: this.validSocialTitle_2, trigger: "blur" }
            ],
            honor: [
                // { validator: this.validSocialTitle_2, trigger: "blur" }
            ],
        };
        return opt;
    }

    /**监听isValidSocialTitle，从而验证社会头衔下的小项 */
    @Watch("isValidSocialTitle")
    validChange(nv: any, ov: any) {
        // if (!nv) {
        this.validItem(this.childName, this.validSocialTitleArr, () => {});
        // }
    }

    /*
     *  获取表单数据
     *  params有pid则请求表单数据，渲染到表单
     *  验证过pid后才会加载表单
     */
    getformData() {
        if (this.$route.params.eid) {
            this.$axios({
                url: `http://ittn_data.ittn.com.cn/api/expert/${this.$route.params.eid}/show`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "api_token"
                    )}`,
                    lang: this.$i18n.locale,
                },
            }).then((res: any) => {
                // this.registFormData = res.data.data;
                // this.registFormData.executive_position= JSON.parse(res.data.data.social_title).executive_position
                this.giveData(res.data.data);
                this.isshow = true;
            });
        } else {
            this.isshow = true;
        }
    }

    // 分发数据到form
    giveData(data: any) {
        let object = this.registFormData;
        const social: any = [
            "executive_position",
            "professional_affiliation",
            "faculty_position",
            "professional_title",
            "honor",
        ];

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                if (data[key] || data.social_title) {
                    if (social.indexOf(key) != -1) {
                        if (JSON.parse(data.social_title)[key])
                            //@ts-ignore
                            object[key] = JSON.parse(data.social_title)[key];
                    } else {
                        if (data[key])
                            //@ts-ignore
                            object[key] = data[key];
                    }
                }
            }
        }
    }

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        signjs.validateForm(formName, this.$refs).then((res) => {
            // console.log(this.registFormData);
            if (res) {
                isloading(); //打开loading遮罩层
                this.setFormData(this.registFormData).then((res: any) => {
                    subExpertBasic(
                        res,
                        this.$route.params.eid
                            ? this.$route.params.eid
                            : undefined
                    ).then((res: any) => {
                        this.$notify({
                            title: res.status === 0 ? "成功" : "失败",
                            message: res.msg,
                            type: res.status === 0 ? "success" : "error",
                        });
                        if (callback) {
                            callback(res.status, res.data);
                        } else {
                            console.error("Regist:No Callback");
                        }
                    });
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdata = JSON.parse(JSON.stringify(data));
        if (sessionStorage.getItem("channel_id")) {
            subdata.channel_id = sessionStorage.getItem("channel_id");
        }
        subdata.social_title = JSON.stringify({
            executive_position: subdata.executive_position,
            professional_title: subdata.professional_title,
            faculty_position: subdata.faculty_position,
            professional_affiliation: subdata.professional_affiliation,
            honor: subdata.honor,
        });

        if (subdata.graduate == "") {
            subdata.graduate = "/";
        }
        if (subdata.degree == "") {
            subdata.degree = "/";
        }
        return subdata;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    message = "";

    /**解决$message异步覆盖问题 */
    notifyPromise = Promise.resolve();
    notify(msg: any) {
        this.notifyPromise = this.notifyPromise
            .then(this.$nextTick)
            .then(() => {
                this.$message.error(msg);
            });
    }
    validate(prop: never, isPass: never, msg?: any) {
        if (!isPass) {
            // this.notify(msg);
            (this as any).$message.closeAll();
            this.$message.error(msg);
        }
    }

    mounted() {
        this.getformData();
    }
}
